import {PageRoute, BasePageDefinitions, PageRouteProvider, BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import AppointmentBooking, {IAppointmentBookingParams} from "@/pages/AppointmentBooking/AppointmentBooking";
import {SelectListItem} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import {Dictionary} from "typescript-collections";
import Admin, {IAdminParams} from "@/pages/Admin/Admin";
import Services, {IServicesParams} from "@/pages/Services/Services";
import Doctor, {IDoctorParams} from "@/pages/Doctor/Doctor";
import Doctors, {IDoctorsParams} from "@/pages/Doctors/Doctors";
import Location, {ILocationParams} from "@/pages/Location/Location";
import RecentPost, {IBlogParams} from "@/pages/RecentPost/RecentPost";
import JobPosting, {IJobPostingParams} from "@/pages/JobPosting/JobPosting";
import Jobs, {IJobsParams} from "@/pages/Jobs/Jobs";
import Pricing, {IPricingParams} from "@/pages/Pricing/Pricing";
import Company, {ICompanyParams} from "@/pages/Company/Company";
import Clinics, {IClinicParams} from "@/pages/Clinics/Clinics";
import OccupationalHealth, {IOccupationalHealthParams} from "@/pages/OccupationalHealth/OccupationalHealth";
import Recent, {IRecentParams} from "@/pages/Recent/Recent";
import ContactSupport, {IContactSupportParameters} from "@/pages/ContactSupport/ContactSupport";
import ServicePageView, {IServicePageViewParams, ServiceType} from "@/pages/ServicePageView/ServicePageView";
import PrivacyPolicy, {IPrivacyPolicyParams} from "@/pages/PrivacyPolicy/PrivacyPolicy";

/**
 * Parameters used in creating a {@link PageRoute}.
 */
export interface IPageRouteParams<TParams extends BasePageParameters> {
    index?: number;
    id?: string;
    params?: TParams | null;
}

export class PageRouteCollection<TParams extends BasePageParameters> {

    /**
     * Name of the page.
     */
    public readonly pageName: string;

    /**
     * URL's which are resolved to the page.
     */
    public readonly aliasUrls: readonly string[];

    /**
     * @param pageName Name of the page.
     * @param customAliasUrls Custom URL's which are resolved to the page. Mainly useful for adding legacy URL's to preserve backwards compatibility.
     *                        Should not contain the name of the page or any localized routes (PageRoutes.X), as they are automatically included.
     */
    public constructor(pageName: string, customAliasUrls?: string[]) {

        const aliasUrls: string[] = [
            pageName,
            ...(customAliasUrls ?? [])
        ];

        const localizerKey: string = "PageRoutes." + pageName;

        Localizer.supportedLanguageCodes
            .forEach(
                languageCode => {
                    const localizedRoute: string = Localizer.getValue(languageCode, localizerKey);

                    if (localizedRoute !== null && localizedRoute !== "" && (localizedRoute !== localizerKey)) {
                        aliasUrls.push(localizedRoute);
                    }
                });

        this.pageName = pageName;

        this.aliasUrls = [
            ...aliasUrls,
            ...aliasUrls.map(aliasUrl => aliasUrl.toUpperCase()),
            ...aliasUrls.map(aliasUrl => aliasUrl.toLowerCase())
        ];
    }

    /**
     * Route to the page.
     */
    public route(routeParams?: IPageRouteParams<TParams>): PageRoute {
        return new PageRoute(this.pageName, routeParams?.index, routeParams?.id, routeParams?.params);
    }
}


export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }

    public getRoutes(): Dictionary<string, PageRoute> {
        return this.routes;
    }


    private readonly routes: Dictionary<string, PageRoute>;

    constructor() {
        super();

        this.routes = new Dictionary<string, PageRoute>();

        const setValues = <TParams extends BasePageParameters>(pageData: PageRouteCollection<TParams>) =>
            pageData
                .aliasUrls
                .map(
                    aliasUrl =>
                        this.routes.setValue(aliasUrl, pageData.route()));

        setValues(PageDefinitions.admin);
        setValues(PageDefinitions.services);
        setValues(PageDefinitions.appointmentBooking);
        setValues(PageDefinitions.doctor);
        setValues(PageDefinitions.doctors);
        setValues(PageDefinitions.jobs);
        setValues(PageDefinitions.pricing);
        setValues(PageDefinitions.company);
        setValues(PageDefinitions.clinics);
        setValues(PageDefinitions.occupationalHealth);
        setValues(PageDefinitions.recent);
        setValues(PageDefinitions.contactSupport);
        setValues(PageDefinitions.recentPost);
        setValues(PageDefinitions.servicePageView)


        Object.freeze(this.routes);
        Object.freeze(this);
    }

    public static readonly logoutRouteName: string = "Logout";

    public static readonly dummyRouteName: string = "Dummy";

    public static readonly dummyRoute: PageRoute = new PageRoute(PageDefinitions.dummyRouteName);

    public static readonly accountRouteName: string = "Account";

    public static readonly loginRouteName: string = "Login";

    public static readonly loginRoute: PageRoute = new PageRoute(PageDefinitions.loginRouteName);


    public static readonly admin: PageRouteCollection<IAdminParams> = new PageRouteCollection(
        nameof(Admin),
    );

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly errorRouteName: string = "Error";

    public static readonly errorRoute: PageRoute = new PageRoute(PageDefinitions.errorRouteName);

    public static readonly contactSupportRouteName: string = "ContactSupport";

    public static readonly contactSupportRoute: PageRoute = new PageRoute(PageDefinitions.contactSupportRouteName);

    public static readonly offlineRouteName: string = "Offline";

    public static readonly offlineRoute: PageRoute = new PageRoute(PageDefinitions.offlineRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";

    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);

    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);


    //Fenix specific pages


    public static readonly services: PageRouteCollection<IServicesParams> = new PageRouteCollection(
        nameof(Services),
    );

    public static readonly servicePageView: PageRouteCollection<IServicePageViewParams> = new PageRouteCollection(
      nameof(ServicePageView),
    );

    public static readonly appointmentBooking: PageRouteCollection<IAppointmentBookingParams> = new PageRouteCollection(
        nameof(AppointmentBooking),
    );

    public static readonly doctor: PageRouteCollection<IDoctorParams> = new PageRouteCollection(
        nameof(Doctor),
    );

    public static readonly doctors: PageRouteCollection<IDoctorsParams> = new PageRouteCollection(
        nameof(Doctors),
    );

    public static readonly location: PageRouteCollection<ILocationParams> = new PageRouteCollection(
        nameof(Location),
    );

    public static readonly blogpost: PageRouteCollection<IBlogParams> = new PageRouteCollection(
        nameof(RecentPost),
    );

    public static readonly jobPosting: PageRouteCollection<IJobPostingParams> = new PageRouteCollection(
        nameof(JobPosting),
    );

    public static readonly jobs: PageRouteCollection<IJobsParams> = new PageRouteCollection(
      nameof(Jobs),
    );

    public static readonly pricing: PageRouteCollection<IPricingParams> = new PageRouteCollection(
      nameof(Pricing),
    );

    public static readonly company: PageRouteCollection<ICompanyParams> = new PageRouteCollection(
      nameof(Company),
    );

    public static readonly clinics: PageRouteCollection<IClinicParams> = new PageRouteCollection(
      nameof(Clinics),
    );

    public static readonly occupationalHealth: PageRouteCollection<IOccupationalHealthParams> = new PageRouteCollection(
      nameof(OccupationalHealth),
    );

    public static readonly recent: PageRouteCollection<IRecentParams> = new PageRouteCollection(
      nameof(Recent),
    );

    public static readonly recentPost: PageRouteCollection<IRecentParams> = new PageRouteCollection(
      nameof(RecentPost),
    );
    
    public static readonly contactSupport: PageRouteCollection<IContactSupportParameters> = new PageRouteCollection(
      nameof(ContactSupport),
    );

    public static readonly privacyPolicy: PageRouteCollection<IPrivacyPolicyParams> = new PageRouteCollection(
      nameof(PrivacyPolicy),
    );
    
    public static readonly privacyPolicyRouteName: string = 'PrivacyPolicy';

    public static readonly privacyPolicyRoute: PageRoute = new PageRoute(PageDefinitions.privacyPolicyRouteName);
    
    public static readonly appointmentBookingRouteName: string = "AppointmentBooking";

    public static readonly appointmentBookingRoute: PageRoute = new PageRoute(PageDefinitions.appointmentBookingRouteName);

    public static readonly servicesRouteName: string = "Services";

    public static readonly serviceRoute: PageRoute = new PageRoute(PageDefinitions.servicesRouteName);

    public static readonly doctorsRouteName: string = "Doctors";

    public static readonly doctorsRoute: PageRoute = new PageRoute(PageDefinitions.doctorsRouteName);

    public static readonly clinicsRouteName: string = "Clinics";

    public static readonly clinicsRoute: PageRoute = new PageRoute(PageDefinitions.clinicsRouteName);

    public static readonly occupationalHealthRouteName: string = "OccupationalHealth";

    public static readonly occupationalHealthRoute: PageRoute = new PageRoute(PageDefinitions.occupationalHealthRouteName);

    public static readonly pricingRouteName: string = "Pricing";

    public static readonly pricingRoute: PageRoute = new PageRoute(PageDefinitions.pricingRouteName);

    public static readonly companyRouteName: string = "Company";

    public static readonly companyRoute: PageRoute = new PageRoute(PageDefinitions.companyRouteName);
    

    public static readonly blogRouteName: string = "Recent";

    //public static readonly blogRoute: PageRoute = new PageRoute(PageDefinitions.blogRouteName);

    public static readonly blogRoute: PageRoute = new PageRoute(PageDefinitions.blogRouteName);
    
    public static readonly blogPostRouteName: string = "RecentPost";

    public static readonly blogPostRoute: (blogpostId?: number | null) =>
        PageRoute = (blogpostId?: number | null) =>
        new PageRoute(PageDefinitions.blogPostRouteName, null, null, {blogpostId});
    public static readonly servicePageRouteName: string = 'ServicePageView'
    public static readonly servicePageRoute: (type?: ServiceType, id?: number | null) =>
      PageRoute = (type?: ServiceType, id?: number | null) =>
      new PageRoute(PageDefinitions.servicePageRouteName, null, null, {type, id})

    public static readonly jobsRouteName: string = "Jobs";
    
    public static readonly jobsRoute: PageRoute = new PageRoute(PageDefinitions.jobsRouteName);

    public static readonly jobPostingRouteName: string = "JobPosting";

    public static readonly jobPostingRoute: PageRoute = new PageRoute(PageDefinitions.jobPostingRouteName);

    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);

    public static readonly applicationSettingsRouteName: string = "ApplicationSettings";

    public static readonly applicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.applicationSettingsRouteName);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly notificationsRouteName: string = "Notifications";

    public static readonly notificationsRoute: PageRoute = new PageRoute(PageDefinitions.notificationsRouteName);

    // #region TemplateAppTasks

    public static readonly templateAppTasksRouteName: string = "TemplateAppTasks/Dashboard";

    public static readonly templateAppTasksRoute: PageRoute = new PageRoute(PageDefinitions.templateAppTasksRouteName);

    // #endregion
}

new PageDefinitions();